.modal > div {
  box-shadow: none;
}

.modal > div > div {
  text-align: center;
}

.modal_mask {
  background: var(--white);
}
