.grouped_column_header {
  border-top-left-radius: 6px;
  background-color: #446ea1;
  border: 1px solid #1d284e;
  border-bottom: 0px;
  font-weight: 700;
  padding: 0.8rem;
  line-height: 0.75rem;
  letter-spacing: 0.15em;
}

.studentName {
  padding-left: 19px;
}
