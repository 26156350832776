.select {
  width: 100%;
  background: var(--white);
  border-radius: 4px;
  padding-left: -0.25rem;
  margin-top: 4px;
}

.label {
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.15em;
  color: #144281;
}

.outerContainer div.select div[class="ant-select-selector"] {
  border: 1px solid #cccccc;
  border-radius: 3px;
}
