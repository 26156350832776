.container {
  margin: 2rem;
}

.tileImage {
  font-size: 5rem;
}

.card {
  height: 12rem;
  width: 15rem;
  background: var(--white);
  color: var(--uomblue);
  box-sizing: border-box;
  border: 1px solid var(--uomblue);
  border-radius: 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: auto;
  /* Center the content */
  display: grid;
  justify-content: center;
  align-items: center;
}

.card_hover {
  background: var(--uomblue);
  color: var(--white);
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.25);
}

.heading {
  font-size: 1.6em;
  font-weight: 600;
  text-decoration: none;
}

@media screen and (max-width: 768px) {
  .container {
    justify-content: center;
    display: grid;
  }
}
