.container {
  background: var(--white);
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.container .icon {
  font-size: 1.2rem;
  padding: 1rem;
  color: var(--hyperblue);
}

.container .col1 {
  text-align: left;
}

.container .col2 {
  text-align: center;
  padding-right: 1rem;
  align-items: center;
  justify-content: center;
  display: flex;
}
.container .search {
  text-align: right;
  padding-right: 1rem;
  cursor: pointer;
}

.optionItem {
  white-space: break-spaces;
  word-break: break-word;
  display: flex;
  justify-content: space-between;
}

.optionItem2 {
  white-space: break-spaces;
  word-break: break-word;
  display: flex;
  justify-content: space-between;
}

.searchIcon {
  width: 1rem;
}

.optionColumn {
  width: 50%;
}

.optionRow {
  display: flex;
  justify-content: space-between;
}

.optionHeaderRight {
  float: right;
  margin-right: 10px;
}

.optionHeader {
  font-size: 0.9rem;
}
