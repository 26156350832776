/* Cohort Name Style */
.studentName {
  color: var(--uomblue);
  font-size: 0.9375rem;
  font-weight: 400;
}

.cohortName {
  color: var(--uomblue);
  font-size: 0.9375rem;
  font-weight: 700;
}

.groupIcon {
  background-color: var(--sneakygrey);
  border: 1px solid var(--uomblue);
  border-radius: 4px;
  margin-right: 0.5rem;
  padding: 2px;
  width: 1.875rem;
  height: 1.875rem;
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25));
}

.groupIcon > img {
  width: 100%;
  height: 100%;
  margin: auto;
  vertical-align: top;
}

.bargeCount {
  margin-left: 10px;
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25));
  vertical-align: middle;
  min-width: 1.875rem;
}

.bargeCount > sup {
  box-shadow: none;
  background: #00a3ff;
}
