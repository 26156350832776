/* Panel Header */
.panelHeaderContainer {
  background: var(--uomblue);
  border: 1px solid var(--uomblue);
  display: grid;
  grid-template-columns: 8.5fr auto;
  border-radius: 6px 6px 0px 0px;
  padding: 0.3rem 2.25rem;
}

.cohortPanelHeaderContainer {
  background: var(--white);
  border: 0;
  display: grid;
  grid-template-columns: auto 8.5fr auto;
  border-radius: 8px 8px 0px 0px;
  padding: 1rem;
}

.wrapper {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.headerName {
  font-weight: 600;
  font-size: 0.875rem;
  letter-spacing: 0.15em;
  color: var(--white);
  text-transform: uppercase;
}

.panelName {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.2rem;
  color: var(--white);
  overflow-wrap: break-word;
  max-width: 100%;
}

.textReplacingLogo {
  font-weight: 600;
  font-size: 1.5rem;
  color: var(--white);
  overflow-wrap: break-word;
  max-width: 100%;
  padding-right: 1rem;
}

.cohortPanelHeaderContainer .headerName {
  font-weight: 600;
  font-size: 0.85rem;
  color: var(--koala);
}

.cohortPanelHeaderContainer .panelName {
  font-weight: 600;
  font-size: 1.85rem;
  color: var(--blackbird);
  line-height: 2rem;
}

.divider {
  border-left: 1px solid var(--white);
  margin: 0 1rem 0 1rem;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .panelName {
    line-height: 1.8rem;
  }
}
