.container {
  display: grid;
  padding: 1.375rem 0;
  grid-auto-flow: column;
  justify-content: space-evenly;
}

@media screen and (max-width: 992px) {
  .container {
    grid-auto-flow: unset;
    grid-template-columns: repeat(3, 1fr);
    padding: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0.1rem;
  }
}

@media screen and (max-width: 576px) {
  .container {
    grid-template-columns: repeat(2, 1fr);
  }
}
