.button {
  margin: 0 0.5rem 0 0.5rem;
}

.header {
  text-align: right;
  height: 2rem;
  margin-bottom: 1rem;
}

.formItem {
  margin: 0;
}

.typoLink {
  margin: 0.7rem;
}

.newTabIcon {
  margin-left: 0.2rem;
}

.autoComplete {
  width: 25rem;
}

.addContactContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.dropDownContainer {
  padding: 0.5rem;
}

.searchInput {
  margin-bottom: 0.5rem;
  display: block;
}

.filterButton {
  width: 5.625rem;
}

.filterIcon {
  color: #1890ff;
}
