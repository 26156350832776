@import "~antd/dist/antd.less";
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700;800&display=swap");

/* global css variables */
:root {
  --white: #ffffff;
  /* uom style guide */
  --mattwhite: #ffffff;
  --sneakygrey: #f4f4f4;
  --koala: #5d6b82;
  --blackbird: #172b4d;
  --uomblue: #094183;
  --hyperblue: #2979ff;
  --garden: #00d1b2;
  --pudding: #fec058;
  --cyberpink: #ff3860;
  --elegantpurple: #8461ff;
  --lighthyperblue: #f3f7ff;
  --lightpudding: #fffaeb;
  --lightcyberpink: #fff3f6;
  /* added for reference - can't be used due to limitation in media queries
     https://stackoverflow.com/questions/40722882/css-native-variables-not-working-in-media-queries
  */
  --screen-md: @screen-md;
  --screen-lg: @screen-lg;
  --screen-xl: @screen-xl;
  --screen-xxl: @screen-xxl;
}

/* global css general styles */
div#root {
  height: 100%;
}

body {
  font-family: "Open Sans";
  font-size: 100%;
  margin: 0; /* remove default body margin of 8px */
}

p {
  font-size: 1em;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.5em;
}

h3 {
  font-size: 1.2em;
}

h4 {
  font-size: 1em;
}

h5 {
  font-size: 0.9em;
}

a {
  text-decoration: none;
}

@primary-color: #094183;