.container {
  display: grid;
  justify-content: center;
  grid-auto-rows: max-content;
  background: #b7c2cf;
  min-height: 1500px;
}

.content {
  margin: 2rem;
  background: var(--mattwhite);
  border: 1px solid var(--uomblue);
  border-radius: 8px 8px 3px 3px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  width: calc(100vw - 4rem);
}

/* Content Container */
.contentContainer {
  padding: 1.5rem 0 1.2rem;
}

.cohortHeaderContainer {
  height: 3rem;
  display: flex;
  align-items: center;
  background: #0075b7;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border: 1px solid #144281;
  border-left: none;
  border-right: none;
  padding: 10px 0;
}

.cohortHeaderContent {
  margin-right: 1.375rem;
  margin-left: 2.25rem;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.09em;
  font-weight: 600;
}

.cohortOptions {
  width: 300px;
}

.cohortOptions > * {
  width: 75%;
}

.changeCohortButton {
  height: 2rem;
  margin-left: 0;
  margin-right: 1.25rem;
  border-radius: 4px;
  font-size: 0.75rem;
  line-height: 0.75rem; /* for center text horizontally*/
  letter-spacing: 0.08em;
  background-color: var(--white);
  color: var(--uomblue);
  border: 0px;
  text-transform: uppercase;
}

.changeCohortButton:hover {
  color: var(--white);
  background-color: var(--uomblue);
}

.percentageSwitch {
  display: grid;
  grid-template-columns: max-content max-content;
  margin: auto;
  padding: 0.5rem;
  font-size: 0.625rem;
  font-weight: 700;
  letter-spacing: 0.15em;
}

.percentageSwitch > .toggleSwitch {
  margin: auto;
  background: var(--koala);
}

.percentageSwitch > span {
  margin: auto auto auto 0.5rem;
}

@media screen and (max-width: 768px) {
  .cohortHeaderContainer {
    height: max-content;
  }

  .changeCohortButton {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
  }

  /* Content Container */
  .contentContainer {
    padding: 0.5rem 0;
  }
  .percentageSwitch {
    grid-template-columns: max-content;
    row-gap: 0.3rem;
    padding: 0.3rem;
  }

  .percentageSwitch > .toggleSwitch {
    margin-left: 0;
    text-align: left;
  }

  .percentageSwitch > span {
    margin-left: 0;
  }

  .extra > .filterButton {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  .cohortHeaderContainer {
    flex-direction: column;
    align-items: flex-start;
    height: max-content;
    padding: 0.3rem 2.25rem;
  }
  .cohortHeaderContent {
    margin-left: 0px;
  }
}
