.container {
  margin: auto;
}

.frame {
  background: transparent;
  margin: 1.25rem;
  border-radius: 6px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.header {
  background-color: var(--uomblue);
  border-radius: 6px 6px 0 0;
  color: var(--mattwhite);
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
}

.header > .title {
  font-size: 0.85rem;
  font-weight: 700;
  letter-spacing: 0.15em;
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: row;
}

.header > .title > .closeIcon {
  position: absolute;
  font-weight: 700;
  right: 1rem;
}

.header > .title > .closeIcon:hover {
  cursor: pointer;
}

.header > .subtitle {
  padding-left: 1rem;
  margin: -0.35rem 0 0.5rem 0;
  font-size: 1.3rem;
  letter-spacing: 0.05em;
  line-height: 1.6rem;
}

.subtitle ~ .title {
  font-size: 2.2rem;
  letter-spacing: 0.05em;
  padding: 0.7rem 1rem 0.5rem;
  line-height: 2.875rem;
}

.content {
  margin: auto;
  background-color: transparent;
  border: 1px solid var(--uomblue);
  border-top: 0;
  border-radius: 0 0 6px 6px;
  overflow: hidden;
}

.noBorder {
  border: unset;
}

@media screen and (max-width: 768px) {
  .subtitle ~ .title {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  .header > .subtitle {
    font-size: 1rem;
  }
}
