.a {
  color: var(--white);
}

.a:link,
.a:visited {
  text-decoration: none;
}

.a:hover,
.a:active {
  text-decoration: underline;
}

.container {
  background: #333333;
  color: var(--white);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
  overflow: auto;
}

.container .p {
  font-style: normal;
  font-weight: 400;
  font-size: 0.85em;
  line-height: 1em;
  color: var(--white);
}

.container .img {
  max-width: 100%;
  height: auto;
}

.container .col2 {
  text-align: center;
}

.container .col2 .img {
  margin-top: 14px;
}

.container .col3 {
  text-align: left;
}

@media screen and (max-width: 992px) {
  .container {
    grid-template-columns: 1fr;
  }

  .container .p {
    font-size: 0.8em;
    margin: 0.6em;
  }

  .container .col3 {
    text-align: center;
  }
}
