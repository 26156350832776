.container {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

@media screen and (max-width: 992px) {
  .container {
    display: block;
  }
}
