.button {
  margin: 0 0.5rem 0 0.5rem;
}

.header {
  text-align: right;
  height: 2rem;
  margin-bottom: 1rem;
}

.formItem {
  margin: 0;
}

.typoLink {
  margin-right: 0.5rem;
}

.addTeachersComboBox {
  width: 25rem;
}
.buttonContainer {
  margin-top: 4px;
}
.addContactContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.footer {
  text-align: center;
  padding-top: 1rem;
}
