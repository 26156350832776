.container {
  width: 100%;
}

.tree {
  font-size: 1rem;
  font-weight: 500;
}

.tree span {
  background-color: transparent !important;
  align-self: center;
}

.tree > div:nth-child(3) > div > div > div > div {
  padding: 0.3rem 0.75rem 0.3rem 0.75rem;
  border: 0;
  border-top: 1px solid #707070;
  align-items: center;
}

.treeNode > span[class~="ant-tree-node-content-wrapper"] {
  cursor: default;
}

/* Specific Styles */
.blackBirdColor {
  color: var(--blackbird);
}

.hyperBlueSwitcher > span[class~="ant-tree-switcher"] > span svg {
  color: var(--hyperblue);
}

.semibold {
  font-weight: 600;
}

.sneakyGreyBackground {
  background: var(--sneakygrey);
}
.outerContainer {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 30px;
}

.titleContainer {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: auto 1fr 30px;
  align-items: center;
}

.questionContainer {
  display: flex;
  align-items: flex-start;
}

.cohortName {
  padding-right: 40px;
}

.question {
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
  min-width: 100px;
  flex-grow: 2;
}

.moreButton {
  border: none;
  background-color: inherit;
}

.cursorPointer {
  cursor: pointer;
}
.noCursor {
  cursor: default;
}

.questionIcon {
  margin-left: 8px;
  margin-right: 8px;
}

@media screen and (max-width: 576px) {
  .titleContainer {
    display: flex;
    flex-direction: column;
    height: fit-content;
    align-items: flex-start;
  }
}
