.container {
  display: grid;
  justify-content: space-evenly;
  background: #b7c2cf;
  grid-auto-rows: max-content;
  width: 100%;
}

.content {
  margin: 2rem auto 2rem auto;
  width: 100%;
}

.content .cardList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.spin {
  position: unset;
}

.spin > div:nth-child(1) > div {
  position: unset;
}

.heading {
  color: var(--blackbird);
  font-family: "Open Sans";
  font-weight: 700;
  font-size: 1.5rem;
  padding: 0.75rem;
  margin: auto;
  letter-spacing: 0.2em;
}

@media screen and (max-width: 992px) {
  .content {
    min-width: 95vw;
  }

  .heading {
    text-align: center;
  }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .content .cardList {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 768px) {
  .content .cardList {
    grid-template-columns: 1fr;
  }
}
