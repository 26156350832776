.container {
  padding: 0.5rem;
  margin: 0 auto;
  color: var(--uomblue);
  text-align: center;
  grid-template-rows: 1.8rem 5.2rem;
  width: 10rem;
  border-radius: 8px;
}

.totalData {
  font-weight: 700;
  font-size: 0.9375rem;
  letter-spacing: 0.15em;
  padding-left: 0.15rem;
  padding-bottom: 0.5625rem;
}

.dataName {
  font-weight: 700;
  font-size: 0.9375rem;
  letter-spacing: 0.15em;
  padding-left: 0.15rem;
  padding-top: 0.5625rem;
  text-transform: uppercase;
}

.mainDataContainer {
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 50%;
  margin: auto;
}

.mainData {
  font-weight: 700;
  font-size: 2.0625rem;
  line-height: 5.5rem;
  color: var(--white);
}

/* Background color setting */
.background_lightblue {
  background: #00a3ff;
}

.background_blue {
  background: #0075b7;
}

.background_uomblue {
  background: #144281;
}

.background_black {
  background: #000000;
}

/* Text color setting */
.textColor_lightblue {
  color: #00a3ff;
}

.textColor_blue {
  color: #0075b7;
}

.textColor_uomblue {
  color: var(--uomblue);
}

.textColor_black {
  color: #000000;
}

.card_hover.container {
  cursor: pointer;
}

.container.card_hover > * {
  color: var(--white);
}

.card_hover .mainDataContainer {
  outline: 2px solid var(--white);
}

@media screen and (max-width: 1200px) {
  .container {
    max-width: 9.5rem;
  }
}

@media screen and (max-width: 992px) {
  .container {
    margin: 0.5rem auto;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0.5rem 0.1rem;
  }
}

@media screen and (max-width: 480px) {
  .container {
    padding: 0.5rem 0;
    width: 100%;
    margin: 0.5rem auto;
    max-width: 35vw;
  }

  .mainDataContainer {
    width: 5rem;
    height: 5rem;
  }

  .mainData {
    font-size: 2rem;
    line-height: 5rem;
  }

  .dataName {
    font-size: 0.75rem;
  }
}
