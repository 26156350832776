/* COLUMNs*/
.table_cell_large_bold {
  font-weight: 600;
  font-size: 1.125rem;
  color: #666666;
}

.question_text {
  color: #666666;
}

.first_grouped_column_header {
  border-top-left-radius: 6px;
  background-color: #446ea1;
  border: 1px solid #1d284e;
  border-bottom: 0px;
  font-weight: 700;
  padding: 0.8rem;
  line-height: 0.75rem;
  letter-spacing: 0.15em;
}

.grouped_column_header {
  background-color: #446ea1;
  border: 1px solid #1d284e;
  border-bottom: 0px;
  border-left: 0px;
  font-weight: 700;
  padding: 0.8rem;
  line-height: 0.75rem;
  letter-spacing: 0.15em;
}
