.container {
  padding: 0.75rem;
}

.card {
  position: relative;
  background: var(--white);
  border: 2px solid #79abff;
  border-radius: 10px;
  -moz-box-shadow: 1px 2px 10px #79abff61;
  -webkit-box-shadow: 1px 2px 10px #79abff61;
  box-shadow: 1px 2px 10px #79abff61;
  min-width: 250px;
  max-width: 320px;
  height: 375px;
  margin: auto;
  overflow: hidden;
}

/* Card Cover */
.cardCover {
  background: var(--sneakygrey);
  height: 60%;
  overflow: hidden;
}

.cardCover > img {
  width: 320px;
  height: 100%;
  display: block;
}

.cardBody {
  max-height: 50%;
}

.cardBody > .cardBodyHeader {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 16px;
  background-color: var(--white);
}

.cardBody > .cardBodyHeader > .cardTitle {
  display: flex;
  color: black;
  font-size: 1rem;
  font-weight: 700;
  line-height: normal;
}

.cardBody > .cardBodyHeader > .cardTitle:hover {
  color: #1890ff;
}

.cardBody > .cardBodyContent {
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 0 16px 16px 16px;
}

.cardBody > .cardBodyContent > .cardLogo > img {
  height: 40px;
}

@media screen and (max-width: 992px) {
  .container {
    width: 100%;
    max-width: unset;
  }
}
