.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content;
  background: #b7c2cf;
  padding: 4rem 25vw 0 25vw;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .container {
    grid-template-columns: 1fr 1fr;
    padding: 2rem 5vw 0 5vw;
  }
}

@media screen and (max-width: 768px) {
  .container {
    grid-template-columns: 1fr;
    padding: 2rem 5vw 0 5vw;
  }
}
