.button {
  margin: 0 0.5rem 0 0.5rem;
}

.header {
  text-align: right;
  height: 2rem;
  margin-bottom: 1rem;
}

.footer {
  text-align: center;
  padding-top: 1rem;
}

.dropDownContainer {
  padding: 0.5rem;
}

.searchInput {
  margin-bottom: 0.5rem;
  display: block;
}

.filterButton {
  width: 5.625rem;
}

.filterIcon {
  color: #1890ff;
}

.buttonContainer {
  margin-top: 4px;
}
.addStudentsComboBox {
  width: 25rem;
}

.addContactContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}
