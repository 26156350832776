/* Table styles */
.result_table {
  width: 100%;
}

@media screen and (max-width: 992px) {
  .result_table {
    display: none;
  }
}

.result_table thead > tr > th:nth-child(n + 2) {
  text-align: center;
}

.result_table thead > tr:last-child > th {
  border-top: 1px solid #1d284e;
  border-bottom: 1px solid #1d284e;
}

/* For alternate table header background color */
.result_table
  thead
  > tr
  > th:nth-child(2n + 5):not([class~="ant-table-cell-fix-left"]) {
  background-color: #26538d;
}

.result_table thead > tr > th {
  background: #446ea1;
  border-radius: 0px;
  font-style: normal;
  font-size: 1rem;
  font-weight: 600;
  line-height: 0.75rem;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  color: var(--white);
  padding: 0.6rem;
  border-bottom: 0px;
}
.result_table thead > tr > th[class~="ant-table-cell-fix-left"] {
  padding: 0.6rem 1rem;
}

.result_table thead > tr > th[class~="ant-table-column-sort"] {
  background: #446ea1;
}

/* column header separators */
.result_table
  thead
  > tr
  > th:nth-child(-n
    + 5):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: var(--white);
  height: 1.3em;
}

/* extra column header separator for questions against student report*/
.question_result_table.result_table
  thead
  > tr
  > th:nth-child(-n
    + 4):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: var(--white);
}

.result_table
  thead
  > tr
  > th[class~="ant-table-column-has-sorters"]:hover::before {
  background-color: var(--white) !important;
}

.question_result_table.result_table
  thead
  > tr
  > th:nth-child(n + 5):nth-child(-n
    + 9):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: #446ea1;
}

.result_table table > thead > tr:first-child th:first-child,
.result_table table > thead > tr:first-child th:last-child {
  border-radius: 0;
}

.result_table tbody > tr > td {
  padding: 0.3125rem 0.25rem 0.25rem 0.3125rem;
  border-bottom: 1px solid #cccccc;
  color: #666666;
}

.result_table tbody > tr > td:nth-child(n + 6) {
  border-right: 1px solid #cccccc;
}

:not(.question_result_table).result_table tbody > tr > td:nth-child(3),
:not(.question_result_table).result_table tbody > tr > td:nth-child(5n + 5),
.question_result_table.result_table tbody > tr > td:nth-child(2),
.question_result_table.result_table tbody > tr > td:nth-child(4),
.question_result_table.result_table tbody > tr > td:nth-child(5n + 9),
.result_table tbody > tr > td[class~="ant-table-cell-fix-left-last"] {
  border-right: 3px solid #bbbbbb;
}

/* Table Row */
.table_row_white {
  color: #666666 !important;
  background: #ffffff;
}

.table_row_blue {
  color: #666666 !important;
  background-color: #e1e8f0;
}

/* this is required for the sticky columns */
.table_row_grey > td {
  background-color: #f0f0f0;
}

/* Table Cell */
.table_cell_no_data {
  background: #dddddd !important;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.table_cell_blue {
  background: #e1e8f0 !important;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.table_cell_text_icon {
  font-size: 1.125rem;
}

.table_cell_incorrect_red {
  background: #a92121;
  border-radius: 5px;
  color: white;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto; /* Required to center box in table cell */
}

.table_cell_correct_green {
  background: #007e33;
  border-radius: 5px;
  color: white;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto; /* Required to center box in table cell */
}

/* survey answer 1 */
.table_cell_circle_1 {
  background-color: #fff2a1;
  height: 1.5625rem;
  width: 1.5625rem;
  border-radius: 50%;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  border: 1px solid #666666;
  margin: auto; /* Required to center box in table cell */
}

/* survey answer 2 */
.table_cell_circle_2 {
  background-color: #ffcf00;
  height: 1.5625rem;
  width: 1.5625rem;
  border-radius: 50%;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  border: 1px solid #666666;
  margin: auto; /* Required to center box in table cell */
}

/* survey answer 3 */
.table_cell_circle_3 {
  background-color: #ffa900;
  height: 1.5625rem;
  width: 1.5625rem;
  border-radius: 50%;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  border: 1px solid #666666;
  margin: auto; /* Required to center box in table cell */
}

/* survey answer 4 */
.table_cell_circle_4 {
  background-color: #ff8e00;
  height: 1.5625rem;
  width: 1.5625rem;
  border-radius: 50%;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  border: 1px solid #666666;
  margin: auto; /* Required to center box in table cell */
}

/* survey answer 5 */
.table_cell_circle_5 {
  background-color: #ff7500;
  height: 1.5625rem;
  width: 1.5625rem;
  border-radius: 50%;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  border: 1px solid #666666;
  margin: auto; /* Required to center box in table cell */
}

.table_cell_blue_rectangle {
  background-color: #0075b7;
  color: white;
  display: flex;
  font-weight: 600;
  line-height: 20px;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  height: 1.5rem;
  width: 2.5rem;
  border-radius: 5px;
  margin: auto; /* Required to center box in table cell */
}

/* Export Panel and button */
.export_panel {
  background: rgba(204, 204, 204, 0.37);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid #144281;
  border-radius: 0px 0px 6px 6px;
}

.export_btn {
  border: 1px solid #144281;
  border-radius: 4px;
  font-weight: 600;
  letter-spacing: 0.09em;
  font-size: 0.875rem;
  text-transform: uppercase;
}

.result_table thead > tr {
  background: #f0f0f0;
}

/* hover effect */
.result_table
  thead
  > tr
  > th[class~="ant-table-column-has-sorters"][class~="ant-table-cell-fix-left"]:hover {
  background: #446ea1;
}

.result_table tbody > tr:hover > td {
  background: #f0f3f7 !important;
}

.result_table tbody > tr:hover > td > div > div.table_cell_no_data {
  background: #f0f3f7 !important;
}

.result_table
  tbody
  > tr:nth-child(n + 3)
  > td:first-child
  > div
  > span:last-child {
  padding-left: 9px;
}

.result_table
  tbody
  > tr[class~="ant-table-row-level-0"]
  > td
  > div
  > button[class~="ant-table-row-expand-icon"] {
  display: none;
}

:not(.question_result_table).result_table
  tbody
  > tr[class~="ant-table-row-level-0"]
  > td:first-child
  > div {
  padding-left: 11px;
}
.result_table
  tbody
  > tr[class~="ant-table-row-level-1"]
  > td:first-child
  > div {
  padding-left: 16px;
}
.result_table
  tbody
  > tr[class~="ant-table-row-level-2"]
  > td:first-child
  > div {
  padding-left: 55px;
}
.result_table
  tbody
  > tr[class~="ant-table-row-level-3"]
  > td:first-child
  > div {
  padding-left: 94px;
}
.result_table
  tbody
  > tr[class~="ant-table-row-level-4"]
  > td:first-child
  > div {
  padding-left: 132px;
}
.result_table
  tbody
  > tr[class~="ant-table-row-level-5"]
  > td:first-child
  > div {
  padding-left: 170px;
}
.result_table
  tbody
  > tr[class~="ant-table-row-level-6"]
  > td:first-child
  > div {
  padding-left: 208px;
}
.result_table
  tbody
  > tr[class~="ant-table-row-level-7"]
  > td:first-child
  > div {
  padding-left: 246px;
}
.result_table
  tbody
  > tr[class~="ant-table-row-level-8"]
  > td:first-child
  > div {
  padding-left: 284px;
}
.result_table
  tbody
  > tr[class~="ant-table-row-level-9"]
  > td:first-child
  > div {
  padding-left: 322px;
}
.result_table
  tbody
  > tr[class~="ant-table-row-level-10"]
  > td:first-child
  > div {
  padding-left: 360px;
}

/* sorter style */
.result_table
  thead
  > tr
  > th[class~="ant-table-column-has-sorters"][class~="ant-table-column-sort"]
  > div
  > span
  > span
  > span[class~="active"] {
  color: var(--white); /* highlight active sorter */
}

@media screen and (max-width: 480px) {
  .export_panel {
    justify-content: center;
  }

  .export_btn {
    width: 10rem;
    text-align: center;
    font-size: 0.75rem;
  }
}
