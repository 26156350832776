.container {
  border-radius: 8px;
  padding: 0.5rem;
  margin: auto;
  color: var(--uomblue);
  text-align: center;
  grid-template-rows: 1.8rem 5.2rem;
  min-width: 12vw;
}

.dataName {
  text-align: center;
  font-weight: 700;
  font-size: 0.9375rem;
  letter-spacing: 0.15em;
  padding-top: 0.7rem;
  padding-left: 0.15rem;
  text-transform: uppercase;
  white-space: pre;
}

.mainData {
  font-weight: 700;
  font-size: 3.375rem;
  line-height: 5rem;
  padding-bottom: 0.5rem;
  margin: auto;
}

/* Color Setting */
.card_hover {
  background: #0075b7;
  color: var(--white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .container {
    margin: 1rem auto;
    min-width: 25vw;
  }
}

@media screen and (max-width: 992px) {
  .container {
    min-width: 35vw;
  }
}

@media screen and (max-width: 480px) {
  .container {
    min-width: 20vw;
    margin: 0.25rem;
    padding: 0.2rem 0.25rem;
  }

  .dataName {
    font-size: 0.7rem;
  }
}
