.button {
  margin: 0 0.5rem 0 0.5rem;
}

.header {
  text-align: right;
  height: 2rem;
}

.box {
  height: 5rem;
  padding: 0.5rem;
}

.footer {
  text-align: center;
  padding-top: 1rem;
}
