.dropDownContainer {
  padding: 0.5rem;
}

.searchInput {
  margin-bottom: 0.5rem;
  display: block;
}

.filterButton {
  width: 5.625rem;
}

.filterIcon {
  color: #1890ff;
}
