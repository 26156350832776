.container {
  display: grid;
  justify-content: center;
  grid-auto-rows: max-content;
  background: #b7c2cf;
}

.content {
  margin: auto auto 2rem auto;
  width: 60vw;
  border: 1px solid #707070;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  overflow: hidden;
}

.card {
  /* remove the inner border of card */
  border: 0;
}

.card > div {
  padding: 0;
}

.titleContainer {
  display: grid;
  grid-template-columns: auto 0.5fr;
  margin: 1rem auto 1rem auto;
  width: 100%;
}

.titleContainer > .col2 {
  display: grid;
  grid-template-columns: auto 1fr;
  margin: auto 0 auto auto;
}

.titleContainer .toggleName {
  color: var(--koala);
  font-weight: 600;
  font-size: 1rem;
  margin: auto 1rem auto auto;
}

.titleContainer .toggleSwitch {
  margin: auto;
}

.titleContainer .toggleSwitch.switchOn {
  background: var(--blackbird);
}

.titleContainer .toggleSwitch.switchOff {
  background: var(--koala);
}

.title {
  color: var(--blackbird);
  font-size: 2rem;
  font-weight: 600;
}

.subtitle {
  color: var(--koala);
  font-size: 0.85rem;
  font-weight: 600;
}

@media screen and (max-width: 992px) {
  .content {
    min-width: 95vw;
  }
}
