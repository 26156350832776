.container {
  color: var(--hyperblue);
  text-align: right;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.container > .name {
  font-size: 0.85rem;
}

.container > button {
  background-color: transparent;
  border: none;
  padding: 0 15px;
}

.container > button > .avatar {
  background-color: #8ab5ff;
}

.container > button:hover {
  cursor: pointer;
}

.menu {
  margin-top: 0.7rem;
}

@media screen and (max-width: 576px) {
  .container > .name {
    display: none;
  }

  .container > button {
    padding: 0;
  }
}
