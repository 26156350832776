.container {
  display: grid;
  justify-content: center;
  grid-auto-rows: max-content;
  background: #b7c2cf;
}

.content {
  margin: 2rem;
  width: 50vw;
  border: 1px solid var(--uomblue);
  border-radius: 8px 8px 3px 3px;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
}

.title {
  color: var(--uomblue) !important;
}

.panelHeaderContainer {
  background: var(--uomblue);
  border: 1px solid var(--uomblue);
  border-radius: 8px 8px 0px 0px;
  padding: 1rem;

  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: var(--white);
  max-width: 100%;
}

.panelHeaderContainer .courseName {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: var(--white);
  overflow-wrap: break-word;
  max-width: 100%;
}

@media screen and (max-width: 1600px) {
  .content {
    min-width: 90vw;
  }
}
