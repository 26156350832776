.panelHeaderContainer {
  display: grid;
  grid-template-columns: 1fr 1.5fr 0.5fr;
  background: #f0f0f0;
  border-radius: 0px;
  padding: 1rem;
  border: 1px solid var(--uomblue);
  border-bottom: none;
}

.reportTypeText {
  display: flex;
  align-items: center;
  color: var(--uomblue);
  font-weight: 700;
  font-size: 0.9375rem;
  letter-spacing: 0.05em;
  line-height: 1.18rem;
  text-transform: uppercase;
  white-space: pre;
  margin-bottom: 0.5rem;
}

.reportTypes {
  width: 100%;
  background: var(--white);
  border-radius: 4px;
  padding-left: -0.25rem;
}

.panelHeaderContainer div div.reportTypes div[class="ant-select-selector"] {
  border: 1px solid #cccccc;
  border-radius: 4px;
}

.selectOptions {
  color: #666666;
}
.selectOptions:not(:first-child) {
  border-top: 1px solid #144281;
}

.selectedOption {
  background: #144281 !important;
  color: var(--white) !important;
}
.selectedOption:hover {
  background: #144281;
  text-decoration: underline;
}
.rightContainer {
  margin-top: 1.6875rem;
  display: grid;
  grid-template-columns: 2.4fr 1fr;
}

.popover {
  padding-top: 0px;
}
.popover > div > div[class="ant-popover-arrow"] {
  display: none;
}
.popover > div > div[class="ant-popover-inner"] {
  border-radius: 6px;
}
.popover > div > div[class="ant-popover-inner"] > div {
  padding: 0px;
}

.searchInput {
  width: 16.625rem;
  border: 1px solid #cccccc;
  border-radius: 6px;
}

.filterButton {
  width: 6rem;
  color: #c4c4c4;
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 0.15em;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  margin-left: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.filterButton > span {
  margin-left: 0.15em;
}

.resultsTable {
  position: relative;
  border: 1px solid #1d284e;
  border-top: none;
  border-radius: 0 0 6px 6px;
}

@media screen and (max-width: 992px) {
  .panelHeaderContainer {
    grid-template-columns: auto;
  }

  .rightContainer {
    display: none;
  }
}

@media screen and (max-width: 786px) {
  .reportTypes {
    width: calc(100vw - 8.5rem);
  }
}
