.mainContainer {
  background: #eaeaea;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  width: 700px;
}

.innerContainer {
  padding: 1rem;
}

.selectContainer {
  padding: 0.5rem 0;
}

.columnContainer {
  padding-top: 20px;
  padding-bottom: 10px;
}

.title {
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.15em;
  color: #144281;
}

.footer {
  background: rgba(95, 143, 210, 0.17);
  border-top: 1px solid #b3b3b3;
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}

.button {
  height: 66%;
  border-radius: 4px;
  font-size: 0.75rem;
  border: 1px solid #144281;
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  padding: 10px 14px;
  color: #144281;
}

.primaryButton {
  color: #ffffff;
}

.divider {
  background-color: #b3b3b3;
  height: 100%;
  width: 1px;
}

.contentContainerHeader {
  margin: 0px;
}
