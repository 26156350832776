.container {
  display: grid;
  grid-auto-flow: column;
  padding: 1rem 0;
  justify-content: space-evenly;
}

.divider {
  border-left: 1px solid var(--uomblue);
  margin: 0;
  height: 100%;
}

.contentItemContainer {
  display: grid;
  grid-auto-flow: column;
}

@media screen and (max-width: 1200px) {
  .container {
    grid-auto-flow: unset;
    grid-template-columns: repeat(3, max-content);
    padding: 0.5rem;
  }

  .divider {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .container {
    grid-template-columns: repeat(2, max-content);
  }
}

@media screen and (max-width: 768px) {
  .container {
    grid-template-columns: repeat(2, max-content);
    grid-auto-flow: unset;
  }
}

@media screen and (max-width: 480px) {
  .container {
    grid-template-columns: repeat(2, 1fr);
    padding: 0.3rem 0.1rem;
  }
}
